<template>
  <div class="puzzle-main">
    <h1 class="puzzle-main__title" v-if="!gameIsActive">Выбери пазл</h1>
    <div class="puzzle-main__wrap" v-if="!gameIsActive">
      <div
        class="puzzle-main__item"
        v-for="(puzzle, idx) in puzzles"
        :key="`puzzle-${idx}`"
        @click="chooseImg(puzzle)"
      >
        <img
          :src="require(`../assets/${puzzle.name}.png`)"
          alt=""
          class="puzzle-main__item-img"
        />
      </div>
    </div>
    <puzzle-item @return-to-main="returnToMain" v-else></puzzle-item>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PuzzleGame",
  computed: {
    ...mapGetters(["puzzleData", "activePuzzle"]),
    puzzles() {
      return this.puzzleData;
    },
    gameIsActive() {
      return !!this.activePuzzle;
    },
  },
  methods: {
    ...mapActions(["getActivePuzzle", "getActivePuzzleFromLink"]),
    chooseImg(item) {
      this.getActivePuzzle(item);
      this.changeRoute(item.name);
    },
    returnToMain() {
      this.getActivePuzzle(null);
      this.replaceRoute({ path: "/puzzle" });
    },
    replaceRoute(param) {
      this.$root.$emit("replace-route", param);
    },
    changeRoute(routeParam) {
      this.$root.$emit(
        "change-route",
        `${this.$route.params.game}/${routeParam}`
      );
    },
    getPuzzleNameFromLink() {
      const name = this.$route.path.split("/puzzle/")[1];
      this.getActivePuzzleFromLink(name);
    },
  },
  mounted() {
    this.$root.$on("return-to-main", this.returnToMain);
    this.getPuzzleNameFromLink();
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/base/mixins.scss";

.puzzle-main {
  &__title {
    margin: 35px 0;
    font-size: 40px;
    text-align: center;
    width: 100%;
    font-family: "PolymerDisplay";
    @include tablet {
      font-size: 32px;
      margin: 20px 0 20px;
    }
    @include mobile {
      font-size: 26px;
      margin: 15px 0 15px;
    }
  }

  &__wrap {
    max-width: 1070px;
    margin: 35px auto 0;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    align-items: center;
    padding: 0 30px;
    @include desktop-small {
      max-width: 970px;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    @include mobile {
      flex-direction: column;
      padding: 0 15px;
    }
  }

  &__item {
    position: relative;
    width: 28%;
    height: 250px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid transparent;
    position: relative;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 4px 0 rgb(0 0 0 / 20%);
    cursor: pointer;
    background: #005cff;
    margin-bottom: 20px;

    &:hover {
      transition: 0.3s;
      height: 255px;
    }
    margin-right: 25px;

    @include desktop_small {
      margin-right: 74px;

      &:nth-child(3) {
        margin-right: 0;
      }
    }

    @include tablet {
      height: 230px;
      margin-right: 54px;
    }
    @include mobile {
      width: 255px;
      margin-bottom: 16px;
      margin-right: 0;
    }
  }

  &__item-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
}
</style>
