<template>
  <div class="game">
    <component v-bind:is="currentGameComponent"></component>
  </div>
</template>

<script>
export default {
  name: "GamesView",
  data() {
    return {};
  },
  computed: {
    currentGameComponent() {
      return `${this.$route.path.split("/")[1]}-game`;
    },
  },
};
</script>
