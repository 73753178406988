import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    games: [
      { route: "Puzzle", label: "Пазл" },
      { route: "Memory", label: "Найди пару" },
    ],
    puzzles: [
      {
        title: "Лунный скафандр «Кречет»",
        desc: "В космическом скафандре «Кречет» 1969 года советский космонавт должен был первым ступить на поверхность Луны. Система обеспечения скафандра позволяла провести на Луне рекордно долгое время — до 10 часов, а в нештатной ситуации космонавт мог находиться в нём до 52 часов. <br> Скафандр так и не исполнил своего предназначения: в 1969 году американцы высадились на Луну и пилотируемая лунная программа в СССР была свёрнута. Но решения, найденные при разработке «Кречета», легли в основу современных российских скафандров для работы в открытом космосе.",
        imgType: "vertical",
        name: "space-suit",
      },
      {
        title: "Легковой автомобиль «Руссо-Балт»",
        desc: "«Руссо-Балт» К 12/20 — единственный сохранившийся легковой автомобиль, сделанный в Российской империи. Его выпустил Русско-Балтийский завод в Риге в 1911 году.<br>Автомобиль сначала использовали в гатчинской Офицерской воздухоплавательной академии, потом — в Государственном московском тресте мелкой и средней промышленности. В 30-е годы у автомобиля был частный хозяин. Потом его выкупила Горьковская киностудия. А уже от неё машина после реставрации попала в коллекцию Политехнического музея.",
        imgType: "square",
        name: "russo-balt",
      },
      {
        title: "Электрическая лампа накаливания системы Томаса Эдисона",
        desc: "В середине XIX века учёные провели эксперименты по накаливанию электричеством проволоки и поняли, что этот принцип можно использовать в конструкциях ламп. Но это были лишь эксперименты. <br>В 1870-х годах лампы попали в Америку. Их увидел Томас Эдисон. Он улучшил технологию откачки воздуха из колбы и подобрал подходящий материал для нити накаливания. В Политехническом музее хранится лампа 1880-х годов самой первой эдисоновской конструкции.",
        imgType: "vertical",
        name: "lamp",
      },
      {
        title: "Велосипед «Костотряс»",
        desc: "Такие велосипеды стали выпускать во Франции в 60-х годах XIX века. В отличие от предыдущих моделей — бегунков — у «костотрясов» появились педали и тормоз. А колёса были деревянными с железными ободами. Отсюда и название.<br>С высочайшего одобрения императора Александра II началась история Политехнического музея в 1872 году — и история распространения велосипедов в России. В 1867 году император привёз со Всемирной промышленной выставки в Париже велосипед «Костотряс» — первый велосипед царской семьи.",
        imgType: "square",
        name: "kostotryas",
        isQr: true,
      },
      {
        title: "Деревянные карманные часы <br>(конец XIX века)",
        desc: "Крошечные шестерёнки, корпус, цепочка и футляр мастер Михаил Бронников сделал из разных пород древесины: берёзового капа, пальмового дерева. Изящные стрелки изготовлены из жимолости, а камневые опоры — из кости. Лишь пружина и волосок баланса — из металла.<br>Часовых дел мастера Бронниковы делали часы почти без механических деталей. В «Памятной книжке» губернии за 1870 год о них писали: «Нужно удивляться искусству старика Бронникова и его двух сыновей, которые почти ещё первобытным инструментом вытачивают с изяществом самые маленькие принадлежности часов».",
        imgType: "vertical",
        name: "chasy",
        isQr: true,
      },
    ],
    memoryCards: [
      {
        name: "lightning-detector",
        img: "1.png",
        title: "Грозоотметчик конструкции Александра Попова",
        desc: "Грозоотметчик — одно из первых устройств, принимавших электромагнитные волны. Устройство было создано в конце 1890-х годов. Прибор регистрировал активность атмосферных электрических разрядов — молний. В момент приёма сигнала включался звонок. Конструктор этого устройства — Александр Попов — известен как один из изобретателей радио. ",
      },
      {
        name: "typewriter",
        img: "2.png",
        title: "Пишущая машина Odell",
        desc: "В конце XIX века полноценные пишущие машинки были роскошью. Для тех, кто был небогат, существовали варианты попроще — индексные пишущие машины, такие как эта пишущая машинка Odell 1891–1894 годов. У индексных пишущих машин нет клавиатуры — чтобы напечатать один символ, нужно было найти его среди остальных, передвинуть указатель и нажать на рычажок. Печатать таким способом было медленно и неудобно. Зато конструкция этого устройства была простой, надёжной и дешёвой. ",
      },
      {
        name: "tricycle",
        img: "3.png",
        title: "Трицикл «Кудель»",
        desc: "В конце XIX века многие изобретатели пытались собрать моторный экипаж с бензиновым двигателем. Одной из таких конструкций стал трицикл — трёхколесный велосипед с мотором. По сравнению с дорогими автомобилями трициклы были очень дёшевы. К тому же к трициклу можно было присоединить прицеп или пассажирскую коляску. ",
      },
      {
        name: "phenomobile",
        img: "4.png",
        title: "«Феномобиль»",
        desc: "Первые модели «Феномобиля» были чем-то средним между мотоциклом и автомобилем. У этого «Феномобиля» 1911 года вместо руля длинный Г-образный рычаг, в обиходе прозванный «бычьим хвостом». И всего три колеса. Не самый мощный двигатель — от шести лошадиных сил — размещался над единственным ведущим колесом. «Феномобиль» был недорог и пользовался спросом.",
      },
      {
        name: "airplane",
        img: "5.png",
        title: "Модель самолёта Сикорского «Илья Муромец»",
        desc: "В 1913 году никто не верил, что такой четырёхтонный гигант может подняться в воздух. Первый экспериментальный полёт выполнил сам конструктор — в то время студент Санкт-Петербургского Политехнического института Игорь Сикорский. «Илья Муромец» стал первым в мире самолётом с отдельным от кабины салоном, спальными комнатами и даже ванной с туалетом. ",
      },
      {
        name: "bicycle-lamp",
        img: "6.png",
        title: "Керосиновый велосипедный фонарь Search Light",
        desc: "Этот фонарь для велосипедов был создан в конце XIX века в США. Внутри фонаря — миниатюрная керосиновая горелка. Одной заправки фонаря керосином хватало на шесть часов. Велофонарь давал тусклый оранжевый свет.",
      },
      {
        name: "microscope",
        img: "7.png",
        title: "Микроскоп Гука — Маршала",
        desc: "Микроскопы XVII века давали лишь десятикратное увеличение. Ясное изображение можно было получить только в центре. По краям картинка теряла чёткость, а белый свет раскладывался в радугу. Роберт Гук поместил между линзами микроскопа диафрагму — пластину с маленьким отверстием. Изображение стало более чётким, но и более тёмным. Тогда Гук добавил в конструкцию систему освещения: масляную лампу, сосуд с водой и линзу. Такая конструкция давала увеличение уже до 150 раз.",
      },
      {
        name: "steam-engine",
        img: "8.png",
        title: "Передвижная паровая машина «Локомобиль»",
        desc: "Такие локомобили делали на рубеже XIX–XX веков. Передвижные паровые двигатели позволяли существенно увеличить мощность всевозможных механизмов. В сельском хозяйстве при помощи локомобилей приводили в движение молотилки, веялки, мельницы и насосы. ",
      },
    ],
    activePuzzle: null,
  },
  getters: {
    puzzleData(state) {
      return state.puzzles;
    },
    memoryCardsData(state) {
      return state.memoryCards;
    },
    activePuzzle(state) {
      return state.activePuzzle;
    },
  },
  mutations: {
    setActivePuzzle(state, value) {
      state.activePuzzle = value;
    },
    setActivePuzzleByName(state, name) {
      state.activePuzzle = state.puzzles.filter((puzzle) => {
        return puzzle.name === name;
      })[0];
    },
  },
  actions: {
    getActivePuzzle({ commit }, puzzle) {
      commit("setActivePuzzle", puzzle);
    },
    getActivePuzzleFromLink({ commit }, name) {
      commit("setActivePuzzleByName", name);
    },
  },
  modules: {},
});
