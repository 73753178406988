<template>
  <div
    :class="[
      'card',
      { card_flipped: cardData.isFlipped, card_matched: cardData.isMatched },
    ]"
  >
    <div class="card__back"></div>
    <img
      class="card__front"
      :src="getImgUrl(`${cardData.name}.png`)"
      v-bind:alt="cardData.img"
    />
  </div>
</template>

<script>
export default {
  name: "MemoryCard",
  props: {
    cardData: { type: Object, default: () => {} },
  },
  methods: {
    getImgUrl(pic) {
      return require("../assets/" + pic);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/base/global.scss";

.card {
  width: 23%;
  height: 160px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid transparent;

  position: relative;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  @include tablet {
    height: 140px;
  }
  @include mobile {
    height: 90px;
    margin-bottom: 10px;
  }

  &__front {
    object-fit: contain;
    max-height: 100%;
    width: 100%;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: rotateY(180deg);
    display: none;
  }

  &__back {
    background: $main-color;

    background-image: url("../assets/back.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 10px;
    @include mobile-mini {
      background-size: contain;
    }
  }

  &_flipped {
    transform: rotateY(180deg);
    .card__front {
      display: block;
    }
  }

  &_matched {
    border: 1px solid $main-color;
  }
}
</style>
