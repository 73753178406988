<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  methods: {
    setSoute(path) {
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    replaceRoute(param) {
      this.$router.replace(param);
    },
  },
  mounted() {
    this.$root.$on("change-route", this.setSoute);
    this.$root.$on("replace-route", this.replaceRoute);
  },
};
</script>

<style lang="scss"></style>
