<template>
  <div :class="[isWinning ? 'memory memory_win' : 'memory']">
    <div class="memory__main" v-if="!isWinning">
      <div class="memory__head">
        <h1 class="memory__title">Найди пару</h1>
        <div class="memory__timer" v-if="start">
          Время: <span v-if="min">{{ min }} мин </span>
          <span v-if="sec">{{ sec }} сек</span>
        </div>
      </div>
      <div class="memory__wrap">
        <memory-card
          v-for="(card, idx) in memoryCards"
          :key="`card-${idx}`"
          :cardData="card"
          @click.native="flipCard(card)"
          :isFlipped="card.isFlipped"
        />
      </div>
      <div class="memory__wrap memory__wrap_bot" v-if="start">
        <game-btn
          @btnEvent="restartGame"
          label="Начать сначала"
          prefix="text"
        />
        <div class="memory__steps" v-if="steps">Шагов: {{ steps }}</div>
      </div>
      <h5 class="memory__text">
        Сыграй несколько раз и ты узнаешь историю разных экспонатов нашего музея
      </h5>
    </div>
    <win-view
      v-else
      @return-to-main="returnToMain"
      :itemData="showedItem"
    ></win-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Vue from "vue";
import _ from "lodash";
export default {
  name: "MemoryGame",
  data() {
    return {
      memoryCards: [],
      flippedCards: [],
      start: false,
      steps: 0,
      totalTime: {
        minutes: 0,
        seconds: 0,
      },
      isWinning: false,
      gameCount: 0,
    };
  },
  computed: {
    sec() {
      return this.totalTime.seconds;
    },
    min() {
      return this.totalTime.minutes;
    },
    showedItem() {
      return this.cards[this.gameCount - 1] || null;
    },
    ...mapGetters(["memoryCardsData"]),
    cards() {
      return this.memoryCardsData;
    },
  },
  methods: {
    flipCard(card) {
      if (card.isMatched || card.isFlipped || this.flippedCards.length === 2)
        return;

      if (!this.start) {
        this._startGame();
      }

      card.isFlipped = true;

      if (this.flippedCards.length < 2) this.flippedCards.push(card);
      if (this.flippedCards.length === 2) this._match();
    },
    _startGame() {
      this._tick();
      this.interval = setInterval(this._tick, 1000);
      this.start = true;
    },
    _tick() {
      if (this.totalTime.seconds !== 59) {
        this.totalTime.seconds++;
        return;
      }

      this.totalTime.minutes++;
      this.totalTime.seconds = 0;
    },
    _match() {
      this.steps++;

      if (this.flippedCards[0].name === this.flippedCards[1].name) {
        setTimeout(() => {
          this.flippedCards.forEach((card) => (card.isMatched = true));
          this.flippedCards = [];

          if (this.memoryCards.every((card) => card.isMatched === true)) {
            clearInterval(this.interval);
            this.isWinning = true;
            this.setGameCount();
          }
        }, 400);
      } else {
        setTimeout(() => {
          this.flippedCards.forEach((card) => {
            card.isFlipped = false;
          });
          this.flippedCards = [];
        }, 800);
      }
    },
    restartGame() {
      clearInterval(this.interval);

      this.cards.forEach((card) => {
        Vue.set(card, "isFlipped", false);
        Vue.set(card, "isMatched", false);
      });

      setTimeout(() => {
        this.memoryCards = [];
        this.memoryCards = _.shuffle(
          this.memoryCards.concat(
            _.cloneDeep(this.cards),
            _.cloneDeep(this.cards)
          )
        );
        this.totalTime.minutes = 0;
        this.totalTime.seconds = 0;
        this.start = false;
        this.isWinning = false;
        this.steps = 0;
        this.flippedCards = [];
      }, 300);
    },
    getImgUrl(pic) {
      return require("../assets/" + pic);
    },
    setGameCount() {
      if (this.gameCount < this.cards.length) {
        this.gameCount++;
      } else {
        this.gameCount = 0;
        this.gameCount++;
      }
    },
    returnToMain() {
      this.isWinning = false;
      this.restartGame();
    },
  },
  created() {
    this.cards.forEach((card) => {
      Vue.set(card, "isFlipped", false);
      Vue.set(card, "isMatched", false);
    });

    this.memoryCards = _.shuffle(
      this.memoryCards.concat(_.cloneDeep(this.cards), _.cloneDeep(this.cards))
    );
  },
  mounted() {
    this.$root.$on("return-to-main", this.returnToMain);
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/base/mixins.scss";
.memory {
  @include mobile-mini {
    padding: 0 10px;
  }
  &_win {
    padding: 0;
  }
  &__main {
    width: 780px;
    margin: 35px auto;
    padding: 0 10px;
    display: flex;
    flex-direction: column;

    @include tablet {
      width: 640px;
    }
    @include mobile {
      max-width: 450px;
      width: 100%;
      padding: 0;
    }
  }
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    position: relative;
    align-items: center;
    &_bot {
      margin-top: 15px;
    }
  }

  &__head {
    display: flex;
    flex-direction: column;
    font-size: 32px;
    height: 80px;
    position: relative;
    @include tablet {
      height: 75px;
    }
    @include mobile {
      height: 60px;
    }
  }
  &__title {
    margin: 0 0 10px;
    font-size: 36px;
    text-align: center;
    font-family: "PolymerDisplay";
    @include tablet {
      font-size: 32px;
    }
    @include mobile {
      font-size: 26px;
    }
  }

  &__timer {
    font-size: 20px;
    @include desktop-small {
      font-size: 18px;
    }
    @include mobile {
      font-size: 14px;
    }
  }

  &__steps {
    font-size: 20px;
    @include desktop-small {
      font-size: 18px;
    }
    @include mobile {
      font-size: 14px;
    }
  }

  &__select-wrap {
    width: 100%;
    select {
      width: 100%;
    }
  }

  &__text {
    font-family: "PolymerDisplay";
    font-size: 24px;
    text-align: center;
    margin: 15px 0;
    @include mobile {
      font-size: 16px;
      margin: 15px 10px;
    }
  }
}
</style>
