<template>
  <div :class="[itemData ? `win-view win-view_${itemData.name}` : 'win-view']">
    <div class="win-view__wrap">
      <img
        class="win-view__img"
        :src="require(`../assets/${itemData.name}.png`)"
        alt=""
      />

      <div class="win-view__bg-figure"></div>
      <div class="win-view__info">
        <h2 class="win-view__name" v-html="itemData.title"></h2>
        <p class="win-view__desc" v-html="itemData.desc"></p>
      </div>
      <game-btn
        class="win-view__win-arrow"
        @btnEvent="returnToMain"
        prefix="white"
        :hasIcon="true"
        label="Вернуться"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "WinView",
  props: {
    itemData: { type: Object, default: () => {} },
    gameType: { type: String, default: "" },
  },
  methods: {
    returnToMain() {
      this.$emit("return-to-main");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/base/mixins.scss";

.win-view {
  width: 100%;

  &__wrap {
    position: relative;
    width: fit-content;
    margin: 0 auto;
    padding: 145px 0 106px;
    display: flex;
    flex-direction: column;
    @include tablet {
      width: 100%;
    }
  }

  &_space-suit {
    background: #354898;
    background-image: url(../assets/bg7.png);
    background-position: center;
    background-repeat: no-repeat;
    .win-view__bg-figure {
      background-image: url(../assets/red-figure.svg);
      background-repeat: no-repeat;
    }
    .win-view__info {
      width: 500px;
      top: 350px;
      right: 52px;
    }
    .win-view__img {
      top: 215px;
      width: 431px;
      height: 649px;
      right: 539px;
      z-index: 1;
      @include desktop-small {
        width: 370px;
        top: 235px;
        height: auto;
      }
    }
  }

  &_russo-balt {
    background: #d4ea72;
    background-image: url(../assets/bg6.png);
    background-position: center;
    background-repeat: no-repeat;
    .win-view__bg-figure {
      background-image: url(../assets/blue-figure.svg);
      background-repeat: no-repeat;
      @include mobile {
        background-position: center;
        width: 100%;
      }
    }
    .win-view__info {
      width: 649px;
      top: 446px;
      left: 39px;
    }
    .win-view__img {
      top: 20px;
      width: 725px;
      height: 417px;
      z-index: 1;
    }
  }

  &_lamp {
    background: #354898;
    background-image: url(../assets/bg5.png);
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    .win-view__bg-figure {
      background-image: url(../assets/blue-figure.svg);
      background-repeat: no-repeat;
      background-position: center;
    }
    .win-view__info {
      width: 615px;
      top: 355px;
      left: 53px;
    }
    .win-view__img {
      top: 114px;
      width: 434px;
      height: 769px;
      left: 500px;
      z-index: 1;
    }
  }

  &_lightning-detector {
    background: #d4ea72;
    background-image: url(../assets/bg1.png);
    background-position: center;
    background-repeat: no-repeat;
    @include desktop-xl {
      background-size: 100%;
    }
    .win-view__bg-figure {
      background-image: url(../assets/blue-figure.svg);
      background-repeat: no-repeat;
      @include mobile {
        background-position: center;
        width: 100%;
      }
    }
    .win-view__info {
      width: 649px;
      top: 516px;
      left: 39px;
    }
    .win-view__img {
      top: 0;
      left: -25px;
      width: 770px;
      height: 540px;
      z-index: 1;
      @include mobile-mini {
        width: 320px !important;
      }
    }
  }

  &_typewriter,
  &_chasy {
    background: #005cff;
    background-image: url(../assets/bg6.png);
    background-position: center;
    background-repeat: no-repeat;
    .win-view__bg-figure {
      background-image: url(../assets/red-figure.svg);
      background-repeat: no-repeat;
      @include mobile {
        background-position: center;
        width: 100%;
      }
    }
    .win-view__info {
      width: 649px;
      top: 486px;
      left: 39px;
    }
    .win-view__img {
      top: 11px;
      left: -89px;
      width: 725px;
      height: 482px;
      z-index: 1;
    }
  }

  &_tricycle,
  &_kostotryas {
    background: #a1dda1;
    background-image: url(../assets/bg6.png);
    background-position: center;
    background-repeat: no-repeat;
    .win-view__bg-figure {
      background-image: url(../assets/blue-figure.svg);
      background-repeat: no-repeat;
      @include mobile {
        background-position: center;
        width: 100%;
      }
    }
    .win-view__info {
      width: 649px;
      top: 514px;
      left: 39px;
    }
    .win-view__img {
      top: 0px;
      left: 9px;
      width: 617px;
      height: 569px;
      z-index: 1;
    }
  }

  &_phenomobile {
    background: #005cff;
    background-image: url(../assets/bg3.png);
    background-position: center;
    background-repeat: no-repeat;
    @include desktop-xl {
      background-size: 100%;
    }
    .win-view__bg-figure {
      background-image: url(../assets/red-figure.svg);
      background-repeat: no-repeat;
      @include mobile {
        background-position: center;
        width: 100%;
      }
    }
    .win-view__info {
      width: 649px;
      top: 540px;
      left: 39px;
    }
    .win-view__img {
      top: 125px;
      left: -132px;
      width: 678px;
      height: 451px;
      z-index: 1;
    }
  }

  &_airplane {
    background: #9ce7ff;
    background-image: url(../assets/bg4.png);
    background-position: center;
    background-repeat: no-repeat;
    @include desktop-xl {
      background-size: 100%;
    }
    .win-view__bg-figure {
      background-image: url(../assets/dark-blue-figure.svg);
      background-repeat: no-repeat;
      @include mobile {
        background-position: center;
        width: 100%;
      }
    }
    .win-view__info {
      width: 649px;
      top: 496px;
      left: 39px;
    }
    .win-view__img {
      top: -30px;
      left: -120px;
      width: 1122px;
      height: 528px;
      z-index: 1;
    }
  }
  &_bicycle-lamp {
    background: #d4ea72;
    background-image: url(../assets/bg1.png);
    background-position: center;
    background-repeat: no-repeat;
    @include desktop-xl {
      background-size: 100%;
    }
    .win-view__bg-figure {
      background-image: url(../assets/blue-figure.svg);
      background-repeat: no-repeat;
      @include mobile {
        background-position: center;
        width: 100%;
      }
    }
    .win-view__info {
      width: 649px;
      top: 540px;
      left: 39px;
    }
    .win-view__img {
      top: 0;
      left: 0;
      right: 0;
      width: 446px;
      height: 487px;
      z-index: 1;
    }
  }

  &_microscope {
    background: #ebde72;
    background-image: url(../assets/bg6.png);
    background-position: center;
    background-repeat: no-repeat;
    .win-view__bg-figure {
      background-image: url(../assets/red-figure.svg);
      background-repeat: no-repeat;
      @include mobile {
        background-position: center;
        width: 100%;
      }
    }
    .win-view__info {
      width: 530px;
      top: 350px;
      left: 164px;
    }
    .win-view__img {
      top: 123px;
      left: -275px;
      width: 522px;
      height: 785px;
      z-index: 1;
    }
  }

  &_steam-engine {
    background: #72d672;
    background-image: url(../assets/bg2.png);
    background-position: center;
    background-repeat: no-repeat;
    @include desktop-xl {
      background-size: 100%;
    }
    .win-view__bg-figure {
      background-image: url(../assets/dark-blue-figure.svg);
      background-repeat: no-repeat;
      @include mobile {
        background-position: center;
        width: 100%;
      }
    }
    .win-view__info {
      width: 652px;
      top: 532px;
      left: 39px;
    }
    .win-view__img {
      top: 0;
      left: 235px;
      width: 591px;
      height: 548px;
      z-index: 1;
    }
  }

  &__bg-figure {
    width: 716px;
    height: 829px;
    z-index: 0;
    position: relative;
    display: flex;
    align-items: flex-end;
    margin: 0 auto;
    @include mobile {
      background-position: center;
      width: 100%;
    }
  }

  &__win-arrow {
    cursor: pointer;
    height: auto;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1000;
    bottom: 150px;
    @include mobile-mini {
      bottom: 130px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    color: White;
    z-index: 1;
    position: absolute;
    @include desktop-small {
      width: calc(100% - 40px) !important;
      max-width: 600px;
      padding: 0 20px;
      left: 0 !important;
      right: 0 !important;
      margin: 0 auto;
      top: 380px !important;
    }

    @include mobile-mini {
      top: 330px !important;
    }
  }

  &__name {
    font-family: "PolymerDisplay";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -0.03em;
    margin: 0 0 30px 0;
    @include mobile {
      font-size: 30px;
      line-height: 30px;
    }
    @include mobile-mini {
      font-size: 26px;
      line-height: 26px;
    }
  }

  &__desc {
    max-width: 768px;
    width: 100%;
    margin: auto;
    font-size: 21px;
    line-height: 23px;
    letter-spacing: -0.03em;
    font-weight: 400;
    @include mobile {
      font-size: 18px;
    }
    @include mobile-mini {
      font-size: 16px;
    }
  }

  &__img {
    height: 500px;
    object-fit: contain;
    margin: auto;
    position: absolute;
    @include desktop-small {
      height: auto !important;
      width: 650px !important;
      left: 0 !important;
      right: 0 !important;
      top: 0 !important;
      max-height: 500px;
    }
    @include tablet {
      width: 100% !important;
      max-height: 370px;
    }
    @include mobile-mini {
      max-height: 340px;
    }
  }
}
</style>
